@import 'normalize.css';
@import 'splash';
@import 'animations';

* {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
}

.cdk-global-scrollblock {
    &::-webkit-scrollbar {
        display: none;
    }
}