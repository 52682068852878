@import 'colors';

cool-splash {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100svh;
    background-color: @black-2;
    z-index: 1000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;   
    transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
    pointer-events: none;

    .name {
        color: @white-1;
        font-weight: 700;
        font-size: 32px;
        line-height: 148%;
    }

    .spinner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        width: 56px;

        div {
            width: 12px;
            height: 12px;
            background-color: @orange-1;
            border-radius: 100%;
            display: inline-block;
            -webkit-animation: fuse-bouncedelay 1s infinite ease-in-out both;
            animation: fuse-bouncedelay 1s infinite ease-in-out both;
        }

        .bounce1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
        }

        .bounce2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
        }
    }

    &.hidden {
        display: none;
    }
}


@-webkit-keyframes fuse-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0)
    }
    40% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes fuse-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
    }
}
